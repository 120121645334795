import { registerSplashBtns } from '../../js/splash-btn-service';

let splashBtns = [];

function initSplashBtns() {
  splashBtns = document.querySelectorAll('.splash-btn');

  registerSplashBtns(splashBtns);
}

addEventListener('DOMContentLoaded', () => {
  initSplashBtns();
});
